import * as React from 'react';
import { PageProps } from 'gatsby';

import PresentationLayout from '~/components/layout/Presentation';

export default function typescriptAboutHowWhenUseTypescript({ location: pLocation }: PageProps) {
  return (
    <PresentationLayout pathname={pLocation.pathname}>
      <div className="slides">
        <section>
          <div>
            <img
              src="/img/presentation/typescript/logo.png"
              alt="logo"
              style={{ width: '50%', margin: '0 auto', background: 'none', border: 'none' }}
            />
            <p style={{ margin: '20px 0 0 0' }}>기초부터 시작하는 타입스크립트 강의</p>
            <p style={{ margin: '20px 0 0 0' }}>타입스크립트란? - 언제, 어떻게 사용할 수 있나요?</p>
          </div>
          <aside className="notes"></aside>
        </section>

        <section>
          <div>
            <h2>바닐라 자바스크립트</h2>
            <div style={{ width: '80%', margin: '0 auto' }}>
              <img src="/img/presentation/typescript/about/how-when-use-typescript/vanila-code.jpg" />
            </div>

            <div style={{ width: '80%', margin: '0 auto' }}>
              <img src="/img/presentation/typescript/about/how-when-use-typescript/vanila-babel-result.jpg" />
            </div>

            <div style={{ width: '80%', margin: '0 auto' }}>
              <img src="/img/presentation/typescript/about/how-when-use-typescript/vanila-tsc-result.jpg" />
            </div>
            <aside className="notes">
              타입스크립트는 어떠한 자바스크립트 환경에서도 사용할 수 있습니다. 브라우저, node, deno 상관 없습니다.
              <br />
              설정 또한 babel, webpack, vite, parcel 등 여러 트랜스파일러(transpiler), 번들러(bundler)를 통해 원하는
              방식으로 사용할 수 있습니다. 기본적인 바닐라 자바스크립트 환경에서 사용하는 부분부터 확인해보겠습니다.
              <br />
              --코드--
              <br />
              위와 같은 코드는 document에 접근하기 때문에 브라우저에서 실행될 코드임을 바로 알 수 있습니다.
              applicationHtml에 string 타입이 붙어있기 때문 타입스크립트 코드란 것도 알 수 있죠. 타입스크립트를 우리가
              말하는 프론트엔드 환경에서 실행하려면 어떤 순서를 거쳐야 할까요?
              <br />
              1. 타입스크립트 코드를 자바스크립트 코드로 변환하고
              <br />
              2. 해당 자바스크립트파일을 사용하는 html파일을 서버의 end point로 두고
              <br />
              3. 해당 서버에서 request를 받으면 해당 endpoint로 들어오도록 받아줘야 합니다.
              <br />
              1번 타입스크립트 코드를 자바스크립트 코드로 변환을 하는 방법은 babel과 tsc가 있습니다. 동일한 간단한
              코드도 방법에 따라 결과가 아래와 같이 상이하게 나타나는 것을 볼 수 있습니다. 실제 프로젝트에서 CRA나
              Tsdx(https://www.npmjs.com/package/tsdx)와 같은 bootstraping 도구를 사용하지 않는다면 이러한 방법 중
              자신에게 맞는 방법을 찾아 직접 설정해줘야 합니다.
            </aside>
          </div>
        </section>

        <section>
          <div>
            <h2>Node.js</h2>
            <div>
              <img src="/img/presentation/typescript/about/how-when-use-typescript/node-code.jpg" />
            </div>

            <aside className="notes">
              Node.js에서도 복잡한 코드가 아니며, 자바스크립트와 동일한 문법으로 사용 가능합니다.
              <br />
              --코드--
              <br />
              여기서는 babel과 tsc의 차이를 볼 수 있습니다. ".split" 문법을 사용하여 string array가 나오는 결과에 type이
              number array로 들어가 있습니다. 에러가 나야 하는 상황에 babel은 compile이 성공하고 tsc는 실패합니다.
              <br />
              도구의 차이는 있지만, 실행 자체는 Node.js에서도 문제가 없습니다. 간단히 말씀드리면 babel은 에러 체크 없이
              compile을 하고, tsc는 에러 체크와 compile을 함께하기 때문입니다. 그렇다고 무조건 tsc를 써야한다면 babel의
              typescript 기능은 존재하지 않았겠죠. 자세한 내용은 뒤에서 같이 확인해봅시다.
            </aside>
          </div>
        </section>

        <section>
          <div>
            <h2>Deno</h2>
            <div>
              <a href="https://www.youtube.com/watch?v=M3BM9TB-8yA">Node.js에 관한 10가지 후회</a>
            </div>
            <div>
              <a href="https://github.com/denoland/deno">Deno 프로젝트</a>
            </div>

            <aside className="notes">
              Deno라는 툴이 생소하신 분들이 많으실 겁니다. Deno는 Node.js를 창시한 라이언달이 만든 javascript +
              typescript run-time 환경입니다. "Node.js에 관한 10가지 후회" 발표 후 새로이 만드는 "Deno" 라는 프로젝트를
              공개했습니다.
              <br />
              javascript는 동적 타입 언어 , typescript는 정적 타입 도구인데 이 둘 모두 run-time 환경에서 실행할 수
              있도록 만들은 것입니다. 그렇기 때문에 Deno 환경에서 파일 실행은 별다른 환경 설정 없이 .ts파일을 실행할 수
              있습니다.
            </aside>
          </div>
        </section>

        <section>
          <h1>끝</h1>
          <div>감사합니다.</div>
          <aside className="notes">
            이렇게 여러 환경에서 typescript가 모두 사용될 수 있음을 확인해봤습니다. 어떠셨나요? 조금은 설레지
            않으신가요? 프론트 개발자도, 서버 개발자도, 자바스크립트를 쓰는 모든 환경에서 타입스크립트를 적용할 수
            있습니다. 이제 다음 강의부터 본격적으로 학습을 시작해보겠습니다. 감사합니다.
          </aside>
        </section>
      </div>
    </PresentationLayout>
  );
}
